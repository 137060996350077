import React, { useRef, useEffect } from 'react'
import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { OrbitControls } from 'three/addons/controls/OrbitControls.js'
import './index.scss'

// Grober inhalt: Das wir auch 3D moderlierungen machen können und diese auch in die Webseite einbinden können und man z.B auch erweitern kann das der Nutzer z.B oberflächen verändern kann oder das sich das 3D-Objekt bewegt. Das ist der erste schritt für komplexere anwendungen wos darum geht räume in 3d zu gestalten und dann oberflächen entsprechend auszutauschen durch den Nutzer.

const ThreeDLogo = () => {
  const containerRef = useRef(null)

  useEffect(() => {
    const scene = new THREE.Scene()
    const camera = new THREE.PerspectiveCamera(
      75,
      window.innerWidth / window.innerHeight,
      0.1,
      1000
    )
    camera.position.z = 4

    // Renderer
    const renderer = new THREE.WebGLRenderer()

    containerRef.current.appendChild(renderer.domElement)

    // enable antialiasing (smoother edges)
    renderer.setPixelRatio(window.devicePixelRatio)

    // set size // If mobile set to 80% of screen size else 50%
    renderer.setSize(
      window.innerWidth >= 768
        ? window.innerWidth * 0.3
        : window.innerWidth * 0.8,
      window.innerWidth >= 768
        ? window.innerHeight * 0.3
        : window.innerHeight * 0.4
    )

    // white background
    scene.background = new THREE.Color(0xffffff)

    // controls
    const controls = new OrbitControls(camera, renderer.domElement)
    controls.autoRotate = true
    controls.autoRotateSpeed = 3.0

    // Lights
    const lightIntensity = 2
    const lights = []
    const directions = [
      [0, 0, 1],
      [0, 0, -1],
      [1, 0, 0],
      [-1, 0, 0],
      [0, 1, 0],
      [0, -1, 0]
    ]
    directions.forEach(direction => {
      const light = new THREE.DirectionalLight(0xffffff, lightIntensity)
      light.position.set(...direction)
      scene.add(light)
      lights.push(light)
    })

    // Load 3D-Object
    let object
    const loader = new GLTFLoader()
    loader.load(
      '/logoHw.gltf',
      function(gltf) {
        scene.add(gltf.scene)
      },
      undefined,
      function(error) {
        console.error(error)
      }
    )

    // Animations-Loop
    const animate = function() {
      requestAnimationFrame(animate)
      controls.update()
      renderer.render(scene, camera)
    }

    animate()

    // Clear Scene
    return () => {
      // Clean up controls
      controls.dispose()

      // Clean up renderer
      renderer.dispose()

      // Remove the renderer's DOM element
      if (containerRef.current) {
        containerRef.current.removeChild(renderer.domElement)
      }

      // Clean up lights
      lights.forEach(light => scene.remove(light))

      // Clean up object
      if (object) {
        scene.remove(object)
        object.traverse(node => {
          if (node.geometry) {
            node.geometry.dispose()
          }
          if (node.material) {
            if (Array.isArray(node.material)) {
              node.material.forEach(material => material.dispose())
            } else {
              node.material.dispose()
            }
          }
        })
      }

      // Dispose of the scene
      // scene.dispose()
    }
  }, [])

  return (
    <>
      {/* Schreibe das man bei dem 3D Logo ausprobieren kann reinzuoomen, rauszomen und rotieren */}
      <p style={{ textAlign: 'center', fontStyle: 'italic' }}>
        Sie können das 3D-Logo mit dem Mausrad hinein- und herauszoomen sowie
        durch Drücken und Ziehen drehen.
      </p>
      <div ref={containerRef} className="logo3d" />
    </>
  )
}

export default ThreeDLogo
