import React from 'react'
import Helmet from 'react-helmet'
import {
  Layout,
  BannerSmall,
  TextBox,
  BannerLinks,
  Breadcrumbs,
  BlogSlider,
  Appointment
} from '../../components'

import BannerImage from '../../assets/projektuebersicht.jpg'
import boxes from '../../components/BannerLinks/DefaultBox'
import cards from '../../components/BlogSlider/DefaultCards'
import ThreeLogo from '../../components/3DLogo'
import { generateBreadcrumbsLinks } from '../../utils/index'

import Georg from '../../assets/georg_neu.png'

const SplitScreen = () => {
  const links = generateBreadcrumbsLinks(
    '/blog/interaktive-3d-modelle',
    'Interaktive 3D-Modelle'
  )
  return (
    <Layout>
      <Helmet
        title="Interaktive 3D-Modelle"
        meta={[
          {
            name: 'description',
            content: 'Interaktive 3D-Modelle für Ihre Website!'
          },
          {
            name: 'keywords',
            content:
              'holzweg, 3d, Interaktive 3D-Modelle, websites, interaktiv, 3D-Modelle, 3D-Objekt'
          },
          {
            name: 'image',
            content:
              'https://www.holzweg.com/static/matomo_m-a1b8b0cba88664b4e21ac50941966afe.png'
          },
          {
            property: 'og:description',
            content: 'Interaktive 3D-Modelle für Ihre Website'
          },
          {
            property: 'og:image',
            content:
              'https://www.holzweg.com/static/matomo_m-a1b8b0cba88664b4e21ac50941966afe.png'
          }
        ]}
      />
      <BannerSmall
        image={BannerImage}
        title={<span>Interaktive 3D-Modelle</span>}
      />
      <Breadcrumbs backLinkAlias=" " links={links} />

      <TextBox
        title="Interaktive 3D-Modelle für Ihre Website"
        text={
          <span>
            <p>
              <b>Interaktive 3D-Modelle für ihre Website</b>
              <br />
              In der heutigen digitalen Ära ist es für Unternehmen wichtig,
              innovative Wege zu finden, um ihre Online-Präsenz zu stärken und
              ihren Kunden ein beeindruckendes Erlebnis zu bieten. Eine
              aufstrebende Technologie, die dies ermöglicht, sind interaktive
              3D-Modelle, die nahtlos in Webseiten integriert werden können.
              <br /> <br />
              Unsere neuen Webdesign-Dienstleistungen bieten die Integration von
              interaktiven 3D-Modellen, die es Unternehmen ermöglichen, ihren
              Kunden ein interaktives Erlebnis zu bieten, bei dem Produkte aus
              verschiedenen Blickwinkeln betrachtet werden können.
              <br /> <br />
              <b>Vorteile intaraktiver 3D-Modelle</b>
              <br />
              Mit interaktiven 3D-Modellen können Nutzer tiefer in Produkte
              eintauchen und sie von allen Seiten betrachten. Aber das ist noch
              nicht alles. Unsere Experten haben auch Funktionen entwickelt, die
              es den Nutzern ermöglichen, Oberflächen anzupassen oder das
              3D-Objekt in Bewegung zu setzen. Stellen Sie sich vor, Sie könnten
              die Oberfläche eines Möbelstücks ändern, um verschiedene Farben
              oder Texturen auszuprobieren, oder ein Fahrzeug auf virtuellen
              Straßen fahren lassen.
            </p>
          </span>
        }
      />

      <ThreeLogo />

      <TextBox
        title=" "
        text={
          <span>
            <p>
              Diese Integration bietet Unternehmen die Möglichkeit, Produkte und
              Dienstleistungen auf eine völlig neue Art und Weise zu
              präsentieren und ihren Kunden ein ansprechendes Erlebnis zu
              bieten.
              <br /> <br />
              Kontaktieren Sie uns noch heute, um mehr darüber zu erfahren, wie
              interaktive 3D-Modelle Ihre Online-Präsenz transformieren können.
              Die Zukunft des Webdesigns ist hier, und wir freuen uns darauf,
              Sie auf dieser spannenden Reise zu begleiten.
            </p>
          </span>
        }
      />

      <Appointment image={Georg} />
      <BlogSlider
        cards={cards}
        singleRow={true}
        title="Weitere Blogbeiträge:"
      />
      <TextBox
        title="Walk with us on the holzweg"
        text="Ready für uns? Dann kontaktiere uns zu einem unverbindlichen Beratungsgespräch oder verwende unsere Call-Back Funktion. Wenn der holzweg nach Traumjob-Garantie klingt, dann schaue bei den Stellenangeboten rein oder besuche uns in den sozialen Medien!"
      />
      <BannerLinks boxes={boxes} />
    </Layout>
  )
}

export default SplitScreen
